/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
// Core
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

// Instruments
import sendData from '../../helpers/api/sendData';
import ArrowWhite from '../../assets/svg/arrow-white.svg';
import ArrowBlack from '../../assets/svg/arrow-left-black.svg';
import ArrowYellow from '../../assets/svg/arrow-right-yellow.svg';
import s from './contactForm.module.scss';

const ContactForm = ({ data, theme, btnBackHandler }) => {
  const [success, setSuccess] = useState(false);
  const [formFiles, setFormFiles] = useState([]);
  const [fileError, setfileError] = useState(false);
  const acceptFormat = [
    'application/vnd.ms-excel',
    'application/docx',
    'application/pdf',
    'text/plain',
    'application/msword',
  ];
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptFormat.indexOf(acceptedFiles[0].type) !== -1) {
      setFormFiles([...formFiles, ...acceptedFiles]);
      setfileError(false);
    } else {
      setfileError(true);
    }
  });
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxSize: 5242880,
    onDrop,
  });
  useEffect(() => () => setFormFiles([]), []);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (dataForm, event) => {
    const formData = new FormData();
    setfileError(false);
    formData.append('files', formFiles[0]);
    Object.keys(dataForm).forEach((item) => {
      formData.set(item, dataForm[item]);
    });
    sendData(formData)
      .then((res) => {
        if (res.status === 200) {
          const form = event.target;
          form.reset();
          setFormFiles([]);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        }
      }).catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <div className={`${s.formWrap} ${s[theme]}`}>
      {btnBackHandler
        ? (
          <button type="button" className={`${s.formBtn} ${s.formBack}`} onClick={btnBackHandler}>
            <ArrowBlack />
            <span>{data.button_back}</span>
          </button>
        ) : null }
      <form
        className={s.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`${s.formGroup} ${s.formGroup50}`}>
          <input
            type="text"
            name="yourName"
            placeholder={data.input_name}
            className={`${s.formControl} ${errors.yourName ? s.inputError : ''}`}
            ref={register({ required: data.error_empty })}
          />
          {errors.yourName && <p className={s.errorText}>{errors.yourName.message}</p>}
        </div>
        <div className={`${s.formGroup} ${s.formGroup50}`}>
          <input
            type="text"
            name="companyName"
            placeholder={data.input_company}
            className={`${s.formControl} ${errors.yourName ? s.inputError : ''}`}
            ref={register({ required: data.error_empty })}
          />
          {errors.companyName && <p className={s.errorText}>{errors.companyName.message}</p>}
        </div>
        <div className={`${s.formGroup} ${s.formGroup50}`}>
          <input
            type="email"
            name="email"
            placeholder={data.input_email}
            className={`${s.formControl} ${errors.yourName ? s.inputError : ''}`}
            ref={register({
              required: data.error_empty,
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: data.email_invalid,
              },
            })}
          />
          {errors.email && <p className={s.errorText}>{errors.email.message}</p>}
        </div>
        <div className={`${s.formGroup} ${s.formGroup50}`}>
          <input
            type="text"
            name="phone"
            placeholder={data.input_phone}
            className={`${s.formControl} ${errors.yourName ? s.inputError : ''}`}
            ref={register({
              required: data.error_empty,
              pattern: {
                // eslint-disable-next-line no-useless-escape
                value: /^(?:\+?\d{2}[ -]?[\d -][\d -]+)$/,
                message: data.phone_invalid,
              },
            })}
          />
          {errors.phone && <p className={s.errorText}>{errors.phone.message}</p>}
        </div>
        <div className={s.formGroup}>
          <textarea
            type="text"
            name="yourMessage"
            placeholder={data.input_text}
            className={`${s.formControl} ${errors.yourMessage ? s.inputError : ''}`}
            rows="5"
            ref={register({
              required: data.error_empty,
              maxLength: 200,
              minLength: 1,
            })}
          />
          {errors.yourMessage && <p className={s.errorText}>{errors.yourMessage.message}</p>}
        </div>
        <div className={s.formGroup}>
          <div className={s.formFileBtn} {...getRootProps()}>
            <input
              name="files"
              {...getInputProps()}
            />
            <span>{data.attach}</span>
          </div>
          {fileError && (
            <p className={s.errorText}>
              {data.error_file}
              <div className={s.formatError}>
                <div className={s.formatList}>
                  <span>{data.file_format_title}</span>
                  <div>pdf,</div>
                  <div>msword</div>
                </div>
              </div>
            </p>
          )}
          {formFiles.length ? (
            <aside className={s.formFiles}>
              <ul>
                {formFiles.map((file) => (
                  <li key={uuidv4()}>
                    {`${file.path} - ${parseFloat(file.size / 1024).toFixed(2)} Kb`}
                  </li>
                ))}
              </ul>
            </aside>
          ) : null}
        </div>
        <div className={s.formGroup}>
          {!success ? (
            <button
              type="submit"
              className={`${s.formBtn} ${s.formSubmit}`}
            >
              <span>{data.submit_button}</span>
              {theme === 'contact'
                ? <ArrowWhite />
                : <ArrowYellow />}
            </button>
          ) : (
            <span className={s.success}>{data.success}</span>
          )}
        </div>
      </form>
    </div>
  );
};

ContactForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.string,
  btnBackHandler: PropTypes.func,
};

ContactForm.defaultProps = {
  theme: 'vacancy',
  btnBackHandler: null,
};

export default ContactForm;
