import axios from 'axios';

const url = 'http://esterad-wp.otakoyi.com.ua/wp-json/contact-form-7/v1/contact-forms/972/feedback';
const sendData = (data) => axios.post(url, data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default sendData;
