/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
// Core
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Instruments
import ContactForm from '../contact-form/contactForm';
import ArrowWhite from '../../assets/svg/arrow-white.svg';
import s from './mapPopup.module.scss';

const MapPopup = ({ location, data }) => {
  const [isToggleOpen, setFormOpen] = useState(false);
  useEffect(() => {
    if (location.state && location.state.isOpen) {
      setFormOpen(true);
    }
    return () => {
      setFormOpen(false);
    };
  }, []);

  const renderSection = () => (
    <div className={s.contactPage}>
      <div className={s.contactPageContent}>
        <div className={s.contactPopupContainer}>
          <div className={`${s.popupToggle} ${isToggleOpen ? `${s.isOpen}` : `${s.isClosed}`}`}>
            <div className={s.popupAddress}>
              <div>
                <span>{data.company_name}</span>
                <p>{data.address_1}</p>
                <p>{data.address_2}</p>
                <p>{data.address_3}</p>
              </div>
              <button type="button" className={s.btnOpen} onClick={() => setFormOpen(!isToggleOpen)}>
                <span>{data.button_contact}</span>
                <ArrowWhite />
              </button>
            </div>
            <div className={s.popupForm}>
              <ContactForm
                data={data}
                theme="contact"
                btnBackHandler={() => setFormOpen(!isToggleOpen)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {renderSection()}
    </>
  );
};

MapPopup.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

MapPopup.defaultProps = {
  location: null,
};

export default MapPopup;
