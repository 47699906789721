import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Header from '../../components/header/header';
import MapPopup from '../../components/map-popup/mapPopup';
import VerticalLines from '../../components/vertical-lines/ vertical-lines';
import Layout from '../../layouts/layout';
import { getPageData, setRtl, unsetRtl } from '../../helpers/helpers';

const Contact = ({ location, data }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const isMobile = windowSize <= 1024;
  const wpData = data && getPageData(data, 'contact-ar');
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);
  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
      || document.body.clientWidth;
    setWindowSize(width);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  const showElements = true;

  const renderSection = () => (
    <>
      <Layout
        location={location}
        currentSection={1}
        isMobile={isMobile}
        showFooter={false}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <VerticalLines startAnimation={isLoaded} />
        <MapPopup
          location={location}
          data={
            {
              contact_title: wpData.contact_title,
              company_name: wpData.company_name,
              address_1: wpData.address_1,
              address_2: wpData.address_2,
              address_3: wpData.address_3,
              button_contact: wpData.button_contact,
              button_back: wpData.button_back,
              input_name: wpData.input_name,
              input_company: wpData.input_company,
              input_email: wpData.input_email,
              input_phone: wpData.input_phone,
              input_text: wpData.input_text,
              attach: wpData.attach,
              submit_button: wpData.submit_button,
              success: wpData.success,
              error_empty: wpData.error_empty,
              email_invalid: wpData.email_invalid,
              phone_invalid: wpData.phone_invalid,
              error_file: wpData.error_file,
              file_format_title: wpData.file_format_title,
            }
          }
        />
      </Layout>
      <Header
        currentSection={1}
        isMobile={isMobile}
        showElements={showElements}
        location={location}
      />

    </>
  );

  return (
    <>
      {renderSection()}
    </>
  );
};

Contact.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

Contact.defaultProps = {
  data: null,
};

export default Contact;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          slug
          acf {
            contact_title
            company_name
            address_1
            address_2
            address_3
            button_contact
            button_back
            input_name
            input_company
            input_email
            input_phone
            input_text
            attach
            submit_button
            success
            error_empty
            email_invalid
            phone_invalid
            error_file
            file_format_title

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
        }
      }
    }
  }`;
